exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-index-js": () => import("./../../../src/pages/news/index.js" /* webpackChunkName: "component---src-pages-news-index-js" */),
  "component---src-pages-products-automatic-meter-reading-system-index-js": () => import("./../../../src/pages/products/automatic-meter-reading-system/index.js" /* webpackChunkName: "component---src-pages-products-automatic-meter-reading-system-index-js" */),
  "component---src-pages-products-grid-energy-storage-system-index-js": () => import("./../../../src/pages/products/grid-energy-storage-system/index.js" /* webpackChunkName: "component---src-pages-products-grid-energy-storage-system-index-js" */),
  "component---src-pages-products-partial-discharge-detection-system-index-js": () => import("./../../../src/pages/products/partial-discharge-detection-system/index.js" /* webpackChunkName: "component---src-pages-products-partial-discharge-detection-system-index-js" */),
  "component---src-pages-products-permanent-infrared-detector-index-js": () => import("./../../../src/pages/products/permanent-infrared-detector/index.js" /* webpackChunkName: "component---src-pages-products-permanent-infrared-detector-index-js" */),
  "component---src-pages-products-vibration-monitoring-system-index-js": () => import("./../../../src/pages/products/vibration-monitoring-system/index.js" /* webpackChunkName: "component---src-pages-products-vibration-monitoring-system-index-js" */),
  "component---src-pages-solution-grid-js": () => import("./../../../src/pages/solution/grid.js" /* webpackChunkName: "component---src-pages-solution-grid-js" */)
}

